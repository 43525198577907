import { Button, Col, Form, Input, Row, Checkbox, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { EmailAsyncValidateFormItem } from '../../../../../common/components/helper-form/EmailAsyncValidateFormItem'
import { PasswordFormItem } from '../../../../../common/components/helper-form/PasswordFormItem'
import { Link } from 'react-router-dom'
import { URL_LOGIN_PAGE } from '../../../../login'
// import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google'
// import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
// import { FcGoogle } from 'react-icons/fc'

const Option = Select
const requerido = {
  required: true,
  message: 'Este dato es requerido'
}

function MainRegisterFieldsContainer ({ loading, setIsGoogleUser, setGoogle, setPicture, isOnModal = false }) {
  const form = Form.useFormInstance()

  const [user] = useState([])
  const [profile, setProfile] = useState([])

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => setUser(codeResponse),
  //   onError: (error) => console.log('Login Failed:', error)
  // })
  const URL_TERMS_PAGE = 'https://bookyourprogram.com/policy'

  useEffect(() => {
    if (user && user?.access_token) {
      console.log(user)
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          setProfile(res.data)
          setIsGoogleUser(true)
        })
        .catch((err) => console.log(err))
    }
  }, [user])

  useEffect(() => {
    if (profile.length !== 0 && user) {
      if (profile.picture) setPicture(profile.picture)
      if (profile.id) setGoogle(profile.id)
      form.setFieldsValue({
        name: profile.given_name,
        last_name: profile.family_name,
        email: profile.email,
        password: profile.id + 'Aa!',
        confirm: profile.id + 'Aa!',
        type: 'User',
        terms: true
      })
      form.submit()
    }
  }, [profile])

  return (
    <>
      {/* Name */}
      <Row gutter={12} className=''>
        {/* <Button onClick={() => login()} className='google-bottom'>
          <FcGoogle /> Continuar con google
        </Button>
        <Divider style={{ borderColor: '#BDBDBD' }}>O también</Divider> */}

        <Col xs={24} md={12}>
          <Form.Item
            name='name'
            rules={[requerido]}
          >
            <Input size='large' placeholder='Nombre(s)' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='last_name'
            rules={[requerido]}
          >
            <Input size='large' placeholder='Apellidos' />
          </Form.Item>
        </Col>

      </Row>

      {/* Email */}
      <EmailAsyncValidateFormItem name='email' />
      {/* Password */}
      <PasswordFormItem />

      <Form.Item
        name='tel'
        rules={[requerido]}
      >
        <Input size='large' placeholder='Teléfono' />
      </Form.Item>

      <Form.Item
        name='programs'
        rules={[requerido]}
      >
        <Select placeholder='Programa de interés' size='large' mode='multiple' className='br-1'>
          <Option value='Idiomas'>Idiomas</Option>
          <Option value='Diplomas'>Diplomas</Option>
          <Option value='Certificados'>Certificados</Option>
          <Option value='Licenciaturas'>Licenciaturas</Option>
          <Option value='Maestrías'>Maestrías</Option>
          <Option value='Posgrados'>Posgrados</Option>
          <Option value='Boardings'>Boardings</Option>
          <Option value='Preparatoria'>Preparatoria</Option>
          
        </Select>
      </Form.Item>

      <Form.Item
        name='budget'
        rules={[requerido]}
      >
        <Select placeholder='Presupesto' size='large' className='br-1'>
          <Option value='0,00MXN-50,000MXN'>0,00MXN-50,000MXN</Option>
          <Option value='50,000MXN-80,000MXN'>50,000MXN-80,000MXN</Option>
          <Option value='80,000MXN-120,000MXN'>80,000MXN-120,000MXN</Option>
          <Option value='120,000MXN+'>120,000MXN+</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name='english_level'
        rules={[requerido]}
      >
        <Select placeholder='Nivel de inglés' size='large' className='br-1'>
          <Option value='básico'>Básico</Option>
          <Option value='intermedio'>Intermedio</Option>
          <Option value='avanzado'>Avanzado</Option>
        </Select>
      </Form.Item>

      {/* checkbox Terminos y Condiciones  REQUIRED checkbox */}
      <Form.Item
        name='terms'
        valuePropName='checked'
        rules={[
          {
            required: true,
            message: 'Debes aceptar los términos y condiciones'
          }
        ]}
      >
        <Row gutter={12} style={{ marginBottom: '10px' }}>
          <Col style={{ marginTop: '2px' }}>
            <Checkbox style={{ minHeight: '1rem', minWidth: '1rem' }} />
          </Col>
          <Col xs={21} lg={21} md={22} style={{ paddingLeft: '0px' }}>
            <p className='byp-m-0'>
              Confirmo que he leído y acepto los <Link to={URL_TERMS_PAGE}> <a className='byp-color-red'>Términos y Condiciones</a></Link>
            </p>
          </Col>
        </Row>
      </Form.Item>

      <Row justify='space-between' align='middle'>
        <Col xs={24} md={0}>
          <Form.Item shouldUpdate className='byp-m-0'>
            {() => {
              return (
                <Button
                  size='large'
                  htmlType='submit'
                  loading={loading}
                  block
                  className='byp-btn-red-50'
                  // disabled={!isFormFilled}
                >
                  Registrarme
                </Button>
              )
            }}
          </Form.Item>
        </Col>
        <Col xs={0} md={24}>
          <Form.Item shouldUpdate className='byp-m-0'>
            {() => {
              return (
                <Button
                  size='large'
                  htmlType='submit'
                  loading={loading}
                  block
                  className='byp-btn-red-50'
                  // disabled={!isFormFilled}
                >
                  Registrarme
                </Button>
              )
            }}
          </Form.Item>
        </Col>
        <Col>
          <p className='byp-mt-1'>¿Ya tienes una cuenta? <Link to={URL_LOGIN_PAGE}><a className='byp-color-red' href=''>Iniciar sesión</a></Link></p>
        </Col>
      </Row>
    </>
  )
}

export { MainRegisterFieldsContainer }
